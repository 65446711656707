import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import EditIcon from '@mui/icons-material/Edit';

import {ReactComponent as ChatIcon} from "../../Opportunities/View/icons/ic_chat.svg";
import InstructionsContainer from "../../../components/instructionsContainer";
import OsCard from "../../../components/card/basic";
import OsButton from "../../../components/Button";

import './style.scss';

const CGPProfile = ({cgpUser}) => {
  const [isEditing, setIsEditing] = useState(false);

  const toggle = type => {
    (type === 'completeInfos') && setIsEditing(!isEditing);
  }

  useEffect(() => {
    cgpUser?.phone_number === null && window.location.replace('/sign-up/verify');
  }, [cgpUser])

  return (
    <>
      <Helmet>
        <title>Openstone | Mon profil</title>
      </Helmet>
      <div className="os-flex account-container">
				<OsCard title="Mon profil conseiller">
					<div className="os-flex section-title" style={{ marginBottom: 24 }}>
						<div className="user-infos-top-container">
							{cgpUser?.kyc_verification_status !== 'verified' && (
								<button className="user-infos-top-container__button" onClick={() => toggle('completeInfos')}>
									<EditIcon style={{width: 16}}/>
									Éditer
								</button>
							)}
						</div>
					</div>
						<div className="user-infos-names">
							<div className="user-infos-names">
								<p><span className="field-title">Nom :</span> {cgpUser?.first_name}</p>
								<p><span className="field-title">Prénom :</span> {cgpUser?.last_name}</p>
								<p><span className="field-title">Email :</span> {cgpUser?.email}</p>
								<div className="phone-field">
									<p><span className="field-title">Téléphone :</span> {cgpUser?.phone_number}</p>
								</div>
							</div>
						</div>
						{cgpUser?.kyc_verification_status === 'verified' && (
							<InstructionsContainer
								content={"Votre compte étant maintenant vérifié, vous ne pouvez plus mettre à jour vos informations. Pour toute demande de modification, vous pouvez nous écrire et notre équipe du service client s’occupera de vous !"}
								extraChildren={<OsButton title="Contacter un conseiller" link="mailto:partenaires@openstone.com" variant="secondary" size="small" fit icon={<ChatIcon />}/>}
							/>
						)}
				</OsCard>
				<OsCard title={`Cabinet ${cgpUser.cgp_company?.legal_name}`}>
					<div className="user-infos-names">
						<div className="user-infos-names">
							<p><span className="field-title">Dénomination légale :</span> {cgpUser.cgp_company?.legal_name}</p>
							<p><span className="field-title">Siren :</span> {cgpUser?.cgp_company.siren}</p>
							<p><span className="field-title">ID Orias :</span> {cgpUser?.cgp_company.orias_id}</p>
						</div>
					</div>
				</OsCard>
      </div>
  </>
  );
};

export default CGPProfile;
