function checkDocumentType(documentType) {
	switch (documentType) {
		case 'kyc':
		case 'identity':
			return "Document d'identité";
		case 'residency':
			return 'Justificatif de domicile';
		case 'rib':
			return "Relevé d'identité bancaire";
		case 'status':
			return 'Status';
		case 'kbis':
			return 'KBIS';
		default:
			return 'Document';
	}
}

export {checkDocumentType};
