import React from "react";
// @mui
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
// components
import Scrollbar from '../scrollbar';
import {TableHeadCustom} from './index.js';
import moment from "moment/moment";

import MoneyHelper from "../../Helpers/MoneyHelper";

import './myTable.scss';

export default function FundsCallEstimationTable({ title, subheader, tableData, tableLabels, ...other }) {
    return (
        <Card {...other}>
            <TableContainer sx={{ overflow: 'unset' }}>
                <Scrollbar>
                    <Table sx={{ minWidth: 720 }}>
                        <TableHeadCustom headLabel={tableLabels} />
                        <TableBody>
                            {tableData.map((row, index) =>
                                <MyTableRow key={`tableCell${index}-${row.created_at}-${row.notes}`} row={row} index={index} {...other}/>
                            )}
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>
        </Card>
    );
}

function MyTableRow({ row, ...other }) {
    return (
        <TableRow key={row.id}>
            <TableCell component="th" scope="row" style={{fontSize: 13}}>
                Appel de fond #{row.callNumber}
            </TableCell>
            <TableCell style={{fontSize: 13}}>
                {MoneyHelper.formatEuro(row.amount + other.fees)}
                <p style={{margin: 0, fontSize: 11}}>{row.callNumber === 1 && `Dont frais d'entrée : ${MoneyHelper.formatEuro(other.fees)}`}</p>
            </TableCell>
            <TableCell style={{fontSize: 13}}>{ row.dueDate !== null && moment(row.dueDate).format('DD/MM/YYYY')}</TableCell>
        </TableRow>
    );
}
