import APIs from './Network.ts';

class AuthenticationNetwork extends APIs {
  async requestOTP(phoneNumber, userId) {
    try {
      return await this.post('authentication/phone_number/request', {
        phone_number: phoneNumber,
        user_id: userId !== null && userId,
      });
    } catch (err) {
      return false;
    }
  }

  async requestOTPWeb(phoneNumber, forType) {
    try {
      return await this.post('authentication/phone_number/request_web', {
        phone_number: phoneNumber,
        for_type: forType
      });
    } catch (err) {
      return false;
    }
  }

  async validateOTP(phoneNumber: string, otp: string, authService: string, userId: Number, forType: string) {
    try {
      return await this.post('authentication/phone_number/validate', {
        phone_number: phoneNumber,
        otp_code: otp,
        auth_service: authService,
        user_id: userId,
        for_type: forType
      });
    } catch (err) {
      return false;
    }
  }

  async completeSocialAuth(email: string, firstname: string, lastname: string, authService: string) {
    try {
      return await this.post('authentication/social_auth/complete', {
        email: email,
        first_name: firstname,
        last_name: lastname,
        auth_service: authService,
      });
    } catch (err) {
      return false;
    }
  }

  async loadUserData() {
    try {
      return await this.get('users', {});
    } catch (err) {
      return false;
    }
  }

  async getUserForSurvey() {
    try {
      return await this.get('/users/get_user_for_survey', {});
    } catch (err) {
      return false;
    }
  }

  async editUser(data: any) {
    try {
      return await this.put('users', data);
    } catch (err) {
      return false;
    }
  }

  async changeEmail(data: any) {
    try {
      return await this.put('users/request_email_change', data);
    } catch (err) {
      return false;
    }
  }

  async signUp(provider: string, firstName: string, lastName: string, authService: string, code: string, source: string) {
    try {
      return await this.post('authentication/sign_up', {
        provider: provider,
        first_name: firstName,
        last_name: lastName,
        auth_service: authService,
        code: code,
        source: source
      });
    } catch (err) {
      return false;
    }
  }

  async cgpSignUp(provider: string, firstName: string, lastName: string, companyName: string, siren: string, legalFunction: string, oriasId: string, gender: string, legalType: string, invitationToken: string) {
    try {
      return await this.post('authentication/cgp', {
        provider: provider,
        first_name: firstName,
        company_name: companyName,
        last_name: lastName,
        legal_function: legalFunction,
        siren: siren,
        orias_id: oriasId,
        gender: gender,
        legal_type: legalType,
        invitation_token: invitationToken
      });
    } catch (err) {
      return false;
    }
  }
}

export default new AuthenticationNetwork();
