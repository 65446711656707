import React from "react";

import OsCard from "../../../../components/card/basic";

const Documents = () => (
	<OsCard shadows="no-shadow">
		<div className="os-flex os-card--children-menu">
			<div>
				<h4>Documents</h4>
				<p>Gestion des documents du cabinet.</p>
			</div>
		</div>
	</OsCard>
);

export default Documents;
