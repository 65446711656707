import React from 'react';
import {
	Chart as ChartJS,
	LineElement,
	PointElement,
	LinearScale,
	CategoryScale,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import BarChartLoader from "../../../../components/Loaders/Charts/BarChartLoader";
import OsCard from "../../../../components/card/basic";
import EmptyContent from "../../../../components/empty-content/EmptyContent";
import InsightsIcon from '@mui/icons-material/Insights';

// Register the necessary components
ChartJS.register(
	LineElement,
	PointElement,
	LinearScale,
	CategoryScale,
	Title,
	Tooltip,
	Legend
);

const TransactionsGraph = ({ loading, graphData, title }) => {
	// Define locale date format options for "Month Year"
	const dateOptions = { year: 'numeric', month: 'long' };
	
	// Group data by month-year and sum up the amounts for each month
	const groupedData = graphData && Array.isArray(graphData)
		? graphData.reduce((acc, point) => {
			const date = new Date(point.created_at);
			const monthYear = date.toLocaleDateString('en-US', dateOptions);
			
			if (!acc[monthYear]) {
				acc[monthYear] = 0;
			}
			acc[monthYear] += parseFloat(point.amount);
			return acc;
		}, {})
		: {};
	
	const labels = Object.keys(groupedData);
	const amounts = Object.values(groupedData);
	
	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Montant en €',
				data: amounts,
				borderColor: '#4D878F',
				backgroundColor: '#4D878F',
				pointBorderColor: '#F2F5F8',
				pointBackgroundColor: '#4D878F',
				pointRadius: 5,
				tension: 0.4,
			}
		],
	};
	
	const options = {
		scales: {
			x: {
				grid: {
					display: false, // Hide x-axis grid
				},
			},
		},
	};
	
	const renderGraph = () => {
		if (loading) {
			return <BarChartLoader />;
		} else if (!loading && (!graphData || graphData.length === 0)) {
			return <EmptyContent text="Aucune donnée disponible" illustration={<InsightsIcon /> } />;
		} else if (!loading && graphData.length > 0) {
			return <Line data={data} options={options} />;
		}
	};
	
	return (
		<OsCard title={title} bg="grey">
			{renderGraph()}
		</OsCard>
	);
};

export default TransactionsGraph;
