import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";

import MoneyHelper from "../../Helpers/MoneyHelper";
import NumberHelper from "../../Helpers/NumberHelper";

import Images from "../../assets/images";
import AddCardIcon from '@mui/icons-material/AddCard';
import BoltIcon from '@mui/icons-material/Bolt';
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AuthenticationNetwork from "../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../config/Network/CompanyNetwork.ts";
import InvestmentNetwork from "../../config/Network/InvestmentNetwork.ts";
import CgpNetwork from "../../config/Network/CgpNetwork.ts";

import Video from "./Video";

import SectionDetails from "../../components/Modals/SectionDetails";
import OsModal from "../../components/Modals";
import ValidateIdentityBanner from "../../components/Banner/ValidateIdentityBanner";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SmallSlider from "../../components/Sliders/smallSlider";
import OSButton from "../../components/Button";
import EmptyContent from "../../components/empty-content/EmptyContent";
import {ChevronRight} from "@mui/icons-material";
import OsCard from "../../components/card/basic";
import renderTransactionsTable from "../../components/User/renderTransactionsTable";
import LivretPViewLoader from "../../components/Loaders/LivretP";
import LivrePViewLoaderMobile from "../../components/Loaders/LivretP/Mobile";
import ColoredCard from "../../components/card/ColoredCard";

import {ReactComponent as NoDocs} from "../../assets/Illustrations/no-documents.svg";


import {ReactComponent as Gift} from "../../assets/Illustrations/gift.svg";

import './style.scss';
import DescriptionIcon from "@mui/icons-material/Description";

const LivretP = ({cgp, clientId, clientType, clientView}) => {
  const defaultProfile = cgp ? (clientType !== 'Company') : JSON.parse(localStorage.getItem('defaultProfile'));
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')));
  const [object, setObject] = useState('');
  const [page, setPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [relatedDocs, setRelatedDocs] = useState([]);
  const [loadingRelatedDocs, setLoadingRelatedDocs] = useState(false);
  const [loadingObject, setLoadingObject] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [isShowingVideo, setIsShowingVideo] = useState(false);
  const [isShowingTransactions, setIsShowTransactions] = useState(false);
  const [isShowingActualInterestRate, setIsShowingActualInterestRate] = useState(false);
  const [isShowingBalance, setIsShowingBalance] = useState(false);
  const [isShowingWinnedInterests, setIsShowingWinnedInterests] = useState(false);
  const [isShowingWithdrawForm, setIsShowingWithdrawForm] = useState(false);
  const activeBoosts = !defaultProfile ? object.active_company_boosts_interest_percentage : object.active_user_boosts_interest_percentage
  const actualRate = parseFloat(object.base_interest_percentage) + parseFloat(activeBoosts);
  const navigate = useNavigate();

  const toggle = type => {
    if (type === 'video') {
      setIsShowingVideo(!isShowingVideo);
    } else if (type === 'transactions') {
      setIsShowTransactions(!isShowingTransactions)
    } else if (type === 'actualInterestRate') {
      setIsShowingActualInterestRate(!isShowingActualInterestRate);
    } else if (type === 'balance') {
      setIsShowingBalance(!isShowingBalance);
    } else if (type === 'winnedInterests') {
      setIsShowingWinnedInterests(!isShowingWinnedInterests);
    }
    else if (type === 'withdrawForm') {setIsShowingWithdrawForm(!isShowingWithdrawForm);}
  };

  const interestsInfos = [
    {
      name: "Taux d'intérêts actuel",
      number: NumberHelper.formatPercentage(parseFloat(object?.base_interest_percentage) + parseFloat(activeBoosts)),
      id: 'actualInterestRate',
      baseRate: {
        number: NumberHelper.formatPercentage(object.base_interest_percentage) ,
        title: 'Taux de base',
        content: 'Les intérêts de 4% sont calculés de manière journalière sur votre solde disponible et valide.'
      },
      currentBoost: {
        number: NumberHelper.formatPercentage(activeBoosts),
        title: 'Boost appliqué',
        content: "Les boosts que vous gagnez vous permettent d'augmenter votre taux de base pour un certain temps."
      },
      actualRate: {
        number:  NumberHelper.formatPercentage(actualRate),
        title: 'Taux actuel',
        content: "C'est le taux actuel qui s'applique au solde de votre Livret P. Il comprend votre taux de base + les boosts"
      }
    },
    {
      name: "Solde disponible",
      number: MoneyHelper.formatEuro(object.euro_balance),
      id: 'balance',
      userBalance: {
        number: MoneyHelper.formatEuro(object.euro_balance),
        title: 'Total disponible',
        content: 'Total des fonds déposé sur votre Livret P.'
      },
    },
    {
      name: "Intérêts gagnés",
      number: MoneyHelper.formatEuro(object.lifetime_earned_interests_sum),
      id: 'winnedInterests',
      winnedInterests: {
        number: MoneyHelper.formatEuro(object.lifetime_earned_interests_sum),
        title: 'Total',
        content: "Il s'agit du total des intérêts que vous a rapporté votre Livret P. depuis son ouverture."
      },
      winnedInterestsThisYear: {
        number: MoneyHelper.formatEuro(object.this_year_interests_sum),
        title: 'Année en cours',
        content: "Il s'agit du total des intérêts que vous a rapporté votre Livret P. sur l'année en cours."
      },
      winnedInterestsPastYear: {
        number: MoneyHelper.formatEuro(object.last_year_earned_interests_sum),
        title: "Sur l'année précédente",
        content: "Il s'agit du total des intérêts que vous a rapporté votre Livret P. sur l'année précédente."
      },
    },
  ]

  const handleTransactions = number => {
    setPage(page + number)
  }
  const fillData = async () => {
    setLoadingTransactions(true);
    const response = await InvestmentNetwork.list(
       clientId,
      defaultProfile ? 'User' : 'Company',
      '',
      'livretp',
      'all',
      null,
      null,
      null,
      null,
      1,
      6
    );
    if (response) {
      setTransactions(response.investments);
      setLoadingTransactions(false);
    }
  }

  async function fetchUser() {
    const response = await AuthenticationNetwork.loadUserData();
    if (response) {
      setObject(response)
      setLoadingObject(false);
    }
  }
  async function fetchCompany() {
    const response = await CompanyNetwork.getCompany(company.id);
    if (response) {
      setObject(response);
      setLoadingObject(false);
    }
  }

  async function fetchClient() {
    const response = await CgpNetwork.getClient(clientId, clientType);
    setObject(response);
    setLoadingObject(false);
  }

  const getRelatedDocuments = async () => {
    setLoadingRelatedDocs(true);
    const response = await CgpNetwork.getProductRelatedDocuments('9', clientId, clientType);
    if (response) {
      setRelatedDocs(response);
      setLoadingRelatedDocs(false);
    }
  };

  useEffect(() => {
    if (cgp) {
      fetchClient();
      getRelatedDocuments();
    } else {
      !defaultProfile ? fetchCompany() : fetchUser();
    }
  }, []);

  useEffect(() => {
    if (!cgp) {
      if (object !== '' && !loadingObject) {
        if (!defaultProfile ? object.user.phone_number === null : object.phone_number === null) {
          window.location.replace('/sign-up/verify');
        }
      }
    }
  }, [object])

  useEffect(() => {
    fillData()
  }, [page])

  const InterestsInfo = ({interest}) => (
    <div className="card-infos-interests">
      <h5 className="os-body2 os-h5">{interest.name}</h5>
      <div className="card-infos-interests__more">
        <h4 className="os-h4">{interest.number}</h4>
        <div className="interest-fab" onClick={() => toggle(interest.id)}>
          <ArrowForwardIosIcon />
        </div>
      </div>
    </div>
  );

  const renderBoosts = () => {
    let component;
    if (activeBoosts > 0) {
      component = (
        <p className="boost-text">
          <BoltIcon style={{color: '#FDAD41'}}/>
          Boosts: {NumberHelper.formatPercentage(activeBoosts)} d'intérêts supplémentaires
        </p>
      )
    } else {
      component = (
        <p className="boost-text boost-text__spaced">
          <span style={{display: "flex", alignItems: 'center'}}>
            <BoltIcon style={{color: '#FDAD41'}}/>
            Vous n'avez pas encore de boost actif
          </span>
          <a href="/opportunites/livret-p/boosts" className="boost-text__link">Obtenir un boost</a>
        </p>
      )
    }
    return component;
  }

  const clientDocuments = () => {
    if (loadingRelatedDocs && relatedDocs.length === 0) {
      return 'Chargement...'
    } else if (!loadingRelatedDocs && relatedDocs.length > 0) {
      return (
        <div className="os-flex client-documents">
          {relatedDocs.slice(0, 6).map((doc) => (
            <a className="product-document" href={doc?.url} target="_blank" rel="noreferrer" key={doc?.title}>
	            <div className="pdf-logo-container">
		            <DescriptionIcon />
	            </div>
              <p className="product-document-name os-body2">{doc?.title}</p>
              <ChevronRight/>
            </a>
          ) )}
        </div>
      )
    } else if (!loadingRelatedDocs && relatedDocs.length === 0) {
      return <EmptyContent illustration={<NoDocs/>} text="Aucun document lié à ce produit" />
    }
  }

  const renderUserOrLoader = () => {
    if (loadingObject || object === '') {
      return (
        <div className="loader-display">
          <div className="desktop-display">
            <LivretPViewLoader />
          </div>
          <div className="mobile-display">
            <LivrePViewLoaderMobile />
          </div>
        </div>
      )
    } else if (!loadingObject) {
      return !clientView ? (
        <div>
          {!cgp && <ValidateIdentityBanner object={object} page="livretp" defaultProfile={defaultProfile}/>}
          <div className="livretp-page" style={cgp && {marginTop: "-32px"}}>
            {cgp && (
              <div className="os-flex my-investment-btns">
                <OSButton title="Revenir au profil client" fit size="" variant="no_border" onClick={() => navigate(-1)} icon={<ArrowBackIcon />}/>
                <div className="os-flex gap-16">
                  <OSButton title="Voir la page produit" size="large" variant="primary-full" fit onClick={() => navigate(`${window.location.pathname}`)}/>
                  <OSButton title="Souscrire" size="large" variant="primary" icon={<AddIcon />} fit onClick={() => navigate(`${window.location.pathname}/finalise`, {state: {clientId: clientId, clientType: clientType}})}/>
                </div>
              </div>
            )}
            <div className="os-flex gap-32" style={{marginBottom: 24}}>
              <div className="livretp-white-container" style={cgp && {width: '100%'}}>
                <ColoredCard productInfos={{logo: <img style={{width: "100px", height: "40px"}} src={Images.brand.livretpwhitelogo} alt=""/>}} product={{category_name: "", name: ""}}>
                  <div className="os-flex base-interest">
                    <p className="base-interest-title">Solde disponible</p>
                    <span className="base-interest-percentage">{MoneyHelper.formatEuro(object.euro_balance)}</span>
                  </div>
                  <div className="os-flex base-interest">
                    <p className="base-interest-title">Taux de base</p>
                    <span className="base-interest-percentage">{NumberHelper.formatPercentage(object.base_interest_percentage)}</span>
                  </div>
                  <div className="os-flex base-interest">
                    <p className="base-interest-title">Boosts actifs</p>
                    <span className="base-interest-percentage">{NumberHelper.formatPercentage(defaultProfile ? object.active_user_boosts_interest_percentage : object.active_company_boosts_interest_percentage)}</span>
                  </div>
                </ColoredCard>
              </div>
              {!cgp && (
                <div className="slider-livretp">
                  <SmallSlider func={toggle} />
                </div>
              )}
            </div>
            {!cgp && (
              <div className="livretp-btns os-flex gap-8">
                <a href="/opportunites/livret-p/livretp" className="btn deposit">
                  <AddCardIcon />
                  Déposer
                </a>
                <a href="/opportunites/livret-p/withdraw" className="btn withdraw">
                  <AddCardIcon />
                  Retirer
                </a>
                <a href="/opportunites/livret-p/boosts" className="btn boosts">
                  <BoltIcon />
                  Boosts
                </a>
              </div>
            )}
            {!cgp && (
              <div className="slider-mobile-livretp">
                <SmallSlider func={toggle}/>
              </div>
            )}
            <div className="os-flex livretp-bottom-container" style={cgp && {marginBottom: 32}}>
              <div className="livretp-card transactions-container" style={(!defaultProfile || cgp) && {width: '100%', minHeight: 320}}>
                <div className="transactions-top">
                  <p>Transactions récentes</p>
                </div>
                {renderTransactionsTable(loadingTransactions, transactions, cgp && 'cgp', {name: "Livret P.", category_slug: "livret-p", slug: "livretp"})}
                {transactions.length > 5 &&
                  <div className="see-more-transactions" onClick={() => cgp ? navigate(`${window.location.pathname}/transactions`, {state: {cgp: cgp, clientId: clientId, clientType: clientType}}) : window.location.replace('/opportunites/livret-p/transactions')}>
                    <div className="os-flex gap-8">
                      Voir toutes les transactions
                      <ArrowForwardIosIcon style={{width: 15}}/>
                    </div>
                  </div>
                }
              </div>
              {(defaultProfile && !cgp) && (
                <div className="livretp-card referral-card">
                  <div className="referral-content">
                    <div className="invite-image-container"><EmptyContent illustration={<Gift />} /></div>
                    <h5 className="os-h5">Gagnez plus en invitant vos proches !</h5>
                    <p className="referral-outline os-body2">
                      <span style={{lineBreak: 'anywhere'}}>10€ pour vous + 10€ pour eux.</span>
                      <p style={{margin: 0}}>Gagnez 150€ de bonus au bout de 10 parrainages soit 250€ au total</p>
                    </p>
                    <div style={{width: '30%'}}>
                      <OSButton link="/parrainage" title="Inviter" variant="secondary" size="large"/>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div style={cgp && {marginBottom: 32}}>
              {cgp && <OsCard title="Documents liés à ce produit" children={clientDocuments()}
                              badges={[
                                {number: relatedDocs.filter(doc => doc.status === 'pending').length, text: "En attente de signature", status: "pending"},
                                {number: relatedDocs.filter(doc => doc.status === 'signed').length, text: "Signés", status: "signed"},
                                {number: relatedDocs.filter(doc => doc.status === 'expired').length, text: "Expirés", status: "expired"}
                              ]}/>
              }
            </div>
            {/* ---------- MODALS -------------*/}
            <OsModal isWhite={false} title="" isShowing={isShowingVideo} hide={() => toggle('video')} >
              <Video link="https://www.youtube.com/embed/xO0JsVgBYNM?rel=0&autoplay=1"/>
            </OsModal>
            <OsModal title="Taux d'intérêt actuel" isShowing={isShowingActualInterestRate} hide={() => toggle('actualInterestRate')} >
              <SectionDetails title={interestsInfos[0].baseRate.title} content={interestsInfos[0].baseRate.content} number={interestsInfos[0].baseRate.number} />
              <SectionDetails title={interestsInfos[0].currentBoost.title} content={interestsInfos[0].currentBoost.content} number={interestsInfos[0].currentBoost.number} />
              <SectionDetails title={interestsInfos[0].actualRate.title} content={interestsInfos[0].actualRate.content} number={interestsInfos[0].actualRate.number} />
            </OsModal>
            <OsModal title="Solde disponible" isShowing={isShowingBalance} hide={() => toggle('balance')} >
              <SectionDetails title={interestsInfos[1].userBalance.title} content={interestsInfos[1].userBalance.content} number={interestsInfos[1].userBalance.number} />
            </OsModal>
            <OsModal title="Intérêts gagnés" isShowing={isShowingWinnedInterests} hide={() => toggle('winnedInterests')} >
              <SectionDetails title={interestsInfos[2].winnedInterests.title} content={interestsInfos[2].winnedInterests.content} number={interestsInfos[2].winnedInterests.number} />
              <SectionDetails title={interestsInfos[2].winnedInterestsThisYear.title} content={interestsInfos[2].winnedInterestsThisYear.content} number={interestsInfos[2].winnedInterestsThisYear.number} />
              <SectionDetails title={interestsInfos[2].winnedInterestsPastYear.title} content={interestsInfos[2].winnedInterestsPastYear.content} number={interestsInfos[2].winnedInterestsPastYear.number} />
            </OsModal>
          </div>
        </div>
      ) : renderTransactionsTable(loadingTransactions, transactions, cgp && 'cgp', {name: "Livret P.", category_slug: "livret-p", slug: "livretp"})
    }
  }

  return (
    <div className="livretp-page-container">
      <Helmet>
        <title>Offre | Livret P.</title>
      </Helmet>
      {renderUserOrLoader()}
    </div>
    )
};

export default LivretP;
