import React, {useState} from 'react';

import AuthenticationStore from "../../../stores/AuthenticationStore.ts";
import {ProfileAvatar} from "./Avatar/avatar";
import OSButton from "../../Button";

import LogoutIcon from '@mui/icons-material/Logout';

import './style.scss';

const CgpProfile = ({cgp}) => {
	const [isOpen, setIsOpen] = useState(false);
	
	function logout() {
		AuthenticationStore.cgpSignOut();
		localStorage.clear();
		sessionStorage.clear();
		cgp ? window.location.replace('/sign-in/cgp') : window.location.replace('/');
	}
	
	return (
		<>
			<div className="switch-profile-container" onClick={() => setIsOpen(!isOpen)}>
				<div className="os-flex gap-8">
					<ProfileAvatar profile={cgp} main/>
					<p className="os-body2">
						{cgp?.first_name?.slice(0, 8)} {cgp?.full_name?.length > 8 ? '...' : ''}
					</p>
				</div>
			</div>
			{isOpen && (
				<div className="other-profiles os-flex__column gap-8">
					<ProfileAvatar profile={cgp} menu main />
					<div className="os-flex os-gap-8" style={{justifyContent: "flex-end"}}>
						<OSButton variant="no_border" size="small" title="Se déconnecter" fit onClick={() => logout()} icon={<LogoutIcon />} />
					</div>
				</div>
			)}
		</>
	);
};

export default CgpProfile;
