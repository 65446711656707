import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import './style.scss';

const ModalHeader = ({ cantClose, isWhite, title, hide }) => (
	<div className="modal-header">
		<h5 className="os-h5">{title}</h5>
		{!cantClose && (
			<button
				type="button"
				className={`modal-close ${!isWhite && 'modal-close'}`}
				onClick={hide}
			>
				<span>&times;</span>
			</button>
		)}
	</div>
);

const ModalBody = ({ children }) => <div className="modal-body">{children}</div>;

const OsModal = ({ title, isShowing, hide, children, cantClose, isWhite }) => {
	if (typeof isShowing !== 'boolean') {
		console.error('isShowing should be a boolean indicating whether the modal is visible or not.');
		return null;
	}
	
	const modalClasses = `${isWhite !== false && 'modal-white'} modal small-modal ${cantClose ? '' : 'modal-close-available'}`;
	
	return isShowing
		? ReactDOM.createPortal(
			<div className="modal-overlay">
				<div className="modal-wrapper">
					<div className={modalClasses}>
						<ModalHeader cantClose={cantClose} isWhite={isWhite} title={title} hide={hide} />
						<ModalBody>{children}</ModalBody>
					</div>
				</div>
			</div>,
			document.body
		)
		: null;
};

OsModal.propTypes = {
	title: PropTypes.string.isRequired,
	isShowing: PropTypes.bool.isRequired,
	hide: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	cantClose: PropTypes.bool,
	isWhite: PropTypes.bool,
};

export default OsModal;
