import React from 'react';

import '../NotFound/style.scss';
import OSButton from "../../../components/Button";

const AlreadyAccepted = ({cgp}) => {
	return (
		<div className="not-found-container">
			<h2 className="os-h2">Cette invitation a déjà été acceptée !</h2>
			<p>L'invitation a déjà été acceptée, vous pouvez essayer de vous connecter directement. Si vous observez un problème, n'heistez pas à vous rapprocher de notre équipe</p>
			<div className="os-flex gap-16 not-found-btns">
				<OSButton title="Contacter un conseiller" link="mailto:partenaires@openstone.com" variant="secondary" size="large" fit/>
				<OSButton title="Je me connecte" link="sign-in/partners" variant="primary" size="large" fit/>
			</div>
		</div>
	);
};

export default AlreadyAccepted;
