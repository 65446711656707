import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavItem({ children, ...rest }) {
	const location = useLocation(); // Hook to get the current location
	
	// Get the current path and the target base path
	const currentPath = location.pathname;
	const targetBasePath = rest?.to; // The base path, e.g., "/cgp/cabinets"
	
	// Check if the current path starts with the target base path
	const isActive = currentPath.startsWith(targetBasePath);
	
	return (
		<Link
			to={rest?.to}
			className={`links-container__link ${isActive ? 'links-container__link__active' : ''}`}
			{...rest}
		>
			{children}
		</Link>
	);
}

export default NavItem;
