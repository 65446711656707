import React from 'react';
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import BarChartLoader from "../../../../components/Loaders/Charts/BarChartLoader";
import OsCard from "../../../../components/card/basic";
import EmptyContent from "../../../../components/empty-content/EmptyContent";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

// Register the necessary components
ChartJS.register(
	BarElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip,
	Legend
);

const predefinedBackgroundColors = [
	'rgba(75, 192, 192, 0.2)', // Light green
	'rgba(34, 139, 34, 0.2)',  // Forest green
	'rgba(50, 205, 50, 0.2)',  // Lime green
	'rgba(60, 179, 113, 0.2)', // Medium sea green
	'rgba(46, 139, 87, 0.2)',  // Sea green
	'rgba(144, 238, 144, 0.2)',// Light green
	'rgba(85, 107, 47, 0.2)'   // Dark olive green
];

const predefinedBorderColors = [
	'rgb(75, 192, 192)',      // Light green
	'rgb(34, 139, 34)',       // Forest green
	'rgb(50, 205, 50)',       // Lime green
	'rgb(60, 179, 113)',      // Medium sea green
	'rgb(46, 139, 87)',       // Sea green
	'rgb(144, 238, 144)',     // Light green
	'rgb(85, 107, 47)'        // Dark olive green
];

const generateColors = (numColors) => {
	const colors = [];
	for (let i = 0; i < numColors; i++) {
		const r = Math.floor(Math.random() * 256);
		const g = Math.floor(Math.random() * 256);
		const b = Math.floor(Math.random() * 256);
		colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
	}
	return colors;
};

const ProductInvestmentsGraph = ({ loading, productData, title }) => {
	// Check if productData exists and has items, then use the length to generate dynamic colors
	const backgroundColors = productData && productData.length > predefinedBackgroundColors.length
		? [...predefinedBackgroundColors, ...generateColors(productData.length - predefinedBackgroundColors.length)]
		: predefinedBackgroundColors;
	
	const borderColors = productData && productData.length > predefinedBorderColors.length
		? [...predefinedBorderColors, ...generateColors(productData.length - predefinedBorderColors.length)]
		: predefinedBorderColors;
	
	const data = {
		labels: productData?.map(point => point.product_name || 'Unknown') || [],
		datasets: [
			{
				label: 'Montant en €',
				data: productData?.map(point => parseFloat(point.total) || 0) || [],
				backgroundColor: (productData || []).map((_, index) => backgroundColors[index % backgroundColors.length]),
				borderColor: (productData || []).map((_, index) => borderColors[index % borderColors.length]),
				borderWidth: 1
			}
		]
	};
	
	const options = {
		scales: {
			x: {
				grid: {
					display: false, // Hide x-axis grid
				},
			},
		},
	};
	
	const renderGraph = () => {
		if (loading) {
			return <BarChartLoader />;
		} else if (!loading && (!productData || productData.length === 0)) {
			return <EmptyContent text="Aucune donnée disponible" illustration={<LeaderboardIcon /> } />;
		} else if (!loading && productData.length > 0) {
			return <Bar data={data} options={options} />;
		}
	}
	
	return (
		<OsCard title={title}>
			{renderGraph()}
		</OsCard>
	);
};

export default ProductInvestmentsGraph;
