import React, {useEffect, useState} from 'react';
import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";
import CompaniesTable from "../../../components/Table/CompaniesTable";
import OSButton from "../../../components/Button";
import EmptyContent from "../../../components/empty-content/EmptyContent";

import {ReactComponent as NoData} from "../../../assets/Illustrations/no-documents.svg";

import './style.scss';

const CompaniesList = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState(null);

  async function fetchCompanies() {
    setLoading(true);
    const response = await CompanyNetwork.myCompanies();
    if (response) {
      setCompanies(response);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCompanies()
  }, []);

  const renderCompanies = () => {
    if (loading || companies === null ) {
      return 'Chargement...'
    } else if (!loading && companies.length > 0) {
      return <CompaniesTable tableLabels={[
        { id: 'type', label: 'Type' },
        { id: 'legal_name', label: 'Dénomination légale' },
        { id: 'kyc_status', label: 'État de vérification' },
        { id: 'balance', label: 'Balance' },
        { id: '', label: '' },
      ]} tableData={companies} />
    } else if (!loading && companies.length === 0) {
      return <EmptyContent cta="Ajouter une société" text="Vous n'avez pas encore de société" illustration={<NoData/>} cta={{title: "Ajouter une société", link: '/company/create', variant: "primary-full", size: "large"}}/>
    }
  }
  return (
    <div className="companies-container">
      <div className="os-flex companies-title-container">
        <h4>Mes sociétés</h4>
        <OSButton link="/company/create" variant="primary" title="+ Ajouter une société" size="large" fit />
      </div>
      {renderCompanies()}
    </div>
  );
};

export default CompaniesList;
