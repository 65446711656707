import React from "react";

import DashboardTable from "../Table/DashboardTable";
import EmptyContent from "../empty-content/EmptyContent";
import {ReactComponent as NoData} from "../../assets/Illustrations/no-transactions.svg";
import TableLoader from "../Loaders/Table/TableLoader";

function RenderTable(loadingTransactions, transactions, type, client, clientType, page) {
  let hasInvested = transactions?.products?.some(product => product?.has_invested === true);

  if (!hasInvested && type !== 'cgp') {
    return null
  } else if (loadingTransactions) {
    return <TableLoader />
  } else if (!loadingTransactions && hasInvested) {
    return (
			<div className="table-card" style={page === 'cgpClient' ? {width: "100%"} : {}}>
			<DashboardTable tableData={transactions?.products} subheader={''}
                        tableLabels={[
                          { id: 'name', label: 'Nom du produit' },
                          { id: 'successAmount', label: 'Souscriptions finalisées €' },
                          { id: 'pendingAmount', label: 'Souscriptions en attente €' },
                          { id: 'actions', label: 'Actions' },
                        ]}
                        page={page}
                        client={client}
                        clientType={clientType}
        />
      </div>
    )
  } else if (!loadingTransactions && !hasInvested) {
    return (
      <div className="table-card os-flex table-card--empty">
        <EmptyContent text={type === 'cgp' ? "Le client n'a pas encore d'investissements" : "Vous n'avez pas encore d'investissements"}
                      illustration={<NoData />}
                      dropdown={{
                        title: "Souscrire à un produit",
                        variant: "primary",
                        size:"small",
                        items: client?.available_products,
                        urlState: {clientId: client.id, clientType: clientType},
												fit: true
                      }}
        />
      </div>
    )
  }
}

export default RenderTable;
