import React, { useState } from "react";
import TableLoader from "../../../../components/Loaders/Table/TableLoader";
import EmptyContent from "../../../../components/empty-content/EmptyContent";
import AllInvestmentsTable from "../../../../components/Table/Cgp/AllInvestmentsTable";
import Pagination from '@mui/material/Pagination';
import TableViewIcon from '@mui/icons-material/TableView';

function RenderInvestmentTable({ loading, data }) {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	
	const handleChange = (event, value) => {
		setPage(value);
	};
	
	const getPaginatedData = () => {
		if (!data || data.length === 0) return [];
		const startIndex = (page - 1) * perPage;
		const endIndex = startIndex + perPage;
		return data.slice(startIndex, endIndex);
	};
	
	if (loading) {
		return <TableLoader />;
	}
	
	if (!loading && (!data || data.length === 0)) {
		return <EmptyContent text="Aucune donnée disponible" illustration={<TableViewIcon/>} />;
	}
	
	return (
		<>
			<AllInvestmentsTable
				title="Investissement"
				tableData={getPaginatedData()}
				subheader=""
				tableLabels={[
					{ id: "title", label: "Description" },
					{ id: "member", label: "Collaborateur en charge" },
					{ id: "product_name", label: "Produit" },
					{ id: "created_at", label: "Date" },
					{ id: "amount", label: "Montant" },
					{ id: "status", label: "Statut" },
					{ id: "actions", label: "Actions" },
				]}
			/>
			{(data?.length > perPage || page > 1) && (
				<div className="os-flex pagination-container gap-16 transactions-filters">
					{data?.length > 0 && (
						<Pagination
							count={Math.ceil(data.length / perPage)}
							shape="rounded"
							page={page}
							onChange={handleChange}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default RenderInvestmentTable;
