import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Card,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
	MenuItem,
	Menu,
	IconButton
} from '@mui/material';
import Scrollbar from '../../scrollbar';
import { TableHeadCustom } from '../index.js';
import getBadge from "../../Badge/getBadge";
import MoneyHelper from "../../../Helpers/MoneyHelper";

import '../myTable.scss';
import OSButton from "../../Button";

// ----------------------------------------------------------------------
export default function MembersTable({ title, subheader, tableData, tableLabels, ...other }) {
	return (
		<Card {...other}>
			<TableContainer sx={{ overflow: 'unset' }}>
				<Scrollbar>
					<Table sx={{ minWidth: 720 }}>
						<TableHeadCustom headLabel={tableLabels} />
						
						<TableBody>
							{tableData.map(row => (
								<MembersTableRow key={`${row.id}-${row.full_name}`} row={row} />
							))}
						</TableBody>
					</Table>
				</Scrollbar>
			</TableContainer>
		</Card>
	);
}

// ----------------------------------------------------------------------

function MembersTableRow({ row }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	return (
		<>
			<TableRow>
				<TableCell>
					<div>{row.cgp_user?.full_name}</div>
				</TableCell>
				<TableCell>
					<div>{moment(row.created_at).locale('fr').format('DD/MM/YYYY')}</div>
				</TableCell>
				<TableCell>
					{row.cgp_user?.active_clients_count}
				</TableCell>
				<TableCell>
					{MoneyHelper.formatEuro(row.cgp_user?.aum)}
				</TableCell>
				<TableCell>
					{getBadge(row.role, 'role')}
				</TableCell>
				<TableCell>
					{getBadge(row.cgp_user?.kyc_verification_status, 'kyc_status')}
				</TableCell>
				<TableCell>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-cgp-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
					>
						<MenuItem onClick={() => navigate(`/cgp/cabinets/collaborateurs/${row.cgp_user?.id}`)}>
							Voir le profil
						</MenuItem>
						<MenuItem onClick={() => navigate('/cgp/statistiques', {state: {cgpUserId: row.cgp_user?.id}})}>
							Consulter les statistiques
						</MenuItem>
						<MenuItem onClick={() => navigate('/cgp/clients', {state: {cgpUserId: row.cgp_user?.id}})}>
							Gérer la clientèle
						</MenuItem>
					</Menu>
				</TableCell>
			</TableRow>
		</>
	);
}
