import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import {useNavigate} from "react-router-dom";

import OsCard from "../../../components/card/basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ChevronRight} from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";

import OSButton from "../../../components/Button";
import FullPageLoader from "../../../components/Loaders/FullPage/profile";
import EmptyContent from "../../../components/empty-content/EmptyContent";

import { checkDocumentType } from "../../../Helpers/GeneralHelper";

import CabinetNetwork from "../../../config/Network/CabinetNetwork.ts";

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import InsightsIcon from '@mui/icons-material/Insights';

import "./style.scss";
import getBadge from "../../../components/Badge/getBadge";

const Conseiller = ({ cgpUser }) => {
	const { id } = useParams();
	const [member, setMember] = useState(null);
	const [role, setRole] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	
	async function fetchCgpUser() {
		setLoading(true)
		try {
			const response = await CabinetNetwork.getMember(id, parseInt(cgpUser?.cgp_company?.id));
			setMember(response.cgp_user);
			setRole(response.role);
		} catch (error) {
			console.error("Error fetching cgpUser:", error);
		}
		setLoading(false)
	}
	
	useEffect(() => {
		cgpUser && fetchCgpUser()
	}, [cgpUser]);
	
	const cgpUserInfos = () => {
		return (
			<div className="os-flex cgp-infos-container">
				<div className="os-flex gap-32">
					<div className="os-flex__column">
						<p className="os-body2 profile-info-container"><strong>Prénom:</strong> {member.first_name}</p>
						<p className="os-body2 profile-info-container"><strong>Nom:</strong> {member.last_name}</p>
						<p className="os-body2 profile-info-container"><strong>Email:</strong> {member.email}</p>
						<p className="os-body2 profile-info-container"><strong>Téléphone:</strong> {member.phone_number}</p>
					</div>
					<div className="os-flex__column">
						<p className="os-body2 profile-info-container"><strong>État de vérification:</strong>{getBadge(member.kyc_verification_status, 'kyc_status')}</p>
						<p className="os-body2 profile-info-container"><strong>Cabinet:</strong> {member.cgp_company.legal_name}</p>
						<p className="os-body2 profile-info-container"><strong>Rôle:</strong>{getBadge(role, 'role')}</p>
					</div>
				</div>
			</div>
		);
	}
	
	const cgpUserUploads = () => {
		if (member?.document_uploads?.length > 0) {
			return (
				<div className="os-flex gap-16 collaborator-uploads">
					{member.document_uploads.map((up, index) => (
						<a className="product-document" href={up.url} target="_blank" rel="noreferrer" key={`${up.name}-${index}`}>
							<div className="pdf-logo-container">
								<DescriptionIcon />
							</div>
							<div className="os-flex product-document-name-container">
								<div>
									<p className="product-document-name os-body2">{checkDocumentType(up.document_type)}</p>
									<span className={up.processed ? "success" : "warning"}>{up.processed ? "Vérifié" : "En cours de vérification"}</span>
								</div>
								<ChevronRight />
							</div>
						</a>
					))}
				</div>
			);
		} else {
			return <EmptyContent text="Le collaborateur n'a pas encore ajouté de justificatifs" />;
		}
	}
	
	if (loading || !member) {
		return (
			<div className="clients-container">
				<FullPageLoader />
			</div>
		)
	} else if (!loading && member) {
		return (
			<div className="clients-container">
				<OSButton title="Revenir à la liste des conseillers" fit size="medium" variant="primary-full" link="/cgp/cabinets/collaborateurs" icon={<ArrowBackIcon />} />
				<div className="os-flex clients-top-container">
					<h5 className="os-h5">Vue conseiller - {member?.first_name} {member?.last_name}</h5>
					<div className="os-flex clients-top-container-btns">
						<div className="os-flex gap-16">
							<OSButton
								title="Consulter les statistiques"
								variant="secondary"
								size="large"
								onClick={() => navigate('/cgp/statistiques', {state: {cgpUserId: member?.id}})}
								fit
								disabled={loading}
								icon={<InsightsIcon />}
							/>
							<OSButton
								title="Consulter la clientèle"
								variant="primary"
								onClick={() => navigate('/cgp/clients', {state: {cgpUserId: member?.id}})}
								size="large"
								disabled={loading}
								fit
							/>
						</div>
					</div>
				</div>
				<div className="os-flex__column client-flex-container cgp-top-container">
					<OsCard title=""
									link={`${window.location.pathname}/edition`}
									linkTitle="Éditer"
									conditionForLink={false}
									icon={<EditIcon />}
					>
						{cgpUserInfos()}
					</OsCard>
					<OsCard title="Justificatifs du collaborateur"
									link={`${window.location.pathname}/edition`}
									linkTitle="Ajouter"
									conditionForLink={false}
									icon={<AddIcon />}
					>
						{cgpUserUploads()}
					</OsCard>
				</div>
			</div>
		);
	}
};


export default Conseiller;
