import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UserInvitationNetwork from '../../config/Network/UserInvitationNetwork.ts';
import YourReferralsLoader from "../Loaders/Referral/YoursLoader";
import ReferralsTable from "../Table/ReferralsTable";
import Pagination from '@mui/material/Pagination';

import './style.scss';
import getBadge from "../Badge/getBadge";
import * as yup from "yup";
import {useFormik} from "formik";
import TextField from "@material-ui/core/TextField";
import {MenuItem} from "@mui/material";
import PerPage from "../PerPage";
//import PerPage from "../PerPage";

const YourReferrals = ({ navigation }) => {
  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState(null);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
	const [resetFilter, setResetFilter] = useState(false);
	const [filterValues, setFilterValues] = useState({status: 'all'});
	const [previousFilterValues, setPreviousFilterValues] = useState(null);
	const date = new Date();
	date.setDate(date.getDate() - 30);
	const thirtyDaysAgo = date.toLocaleDateString();
	
	const badges = [
		{number: users.filter(users => users.account_status === 'unverified').length, text: "Inscrits", status: "unverified"},
		{number: users.filter(users => users.account_status === 'verified').length, text: "Compte validés", status: "verified"},
	]

  useEffect(() => {
	  resetFilter && setFilterValues(null);
	  if (filterValues !== null) {
		  getLatestStats(filterValues);
	  } else {
		  getLatestStats({status: 'all'});
	  }
  }, [navigation, page, perPage, resetFilter]);
	
	useEffect(() => {
		if (filterValues !== previousFilterValues) {
			setPreviousFilterValues(filterValues);
			if (page > 1) {
				setPage(1);
			}
			getLatestStats(filterValues);
		} else {
			getLatestStats(filterValues);
		}
	}, [filterValues]);

  const getLatestStats = async ({status, date, startDate, endDate}) => {
    setLoading(true);
    try {
      const reffered_response = await UserInvitationNetwork.getReferredUsers({ page, perPage, status, date, startDate, endDate });
			
      if (reffered_response.users.length > 0) {
        setUsers(reffered_response.users|| []);
				setMeta(reffered_response)
      }
    } catch (error) {
      //console.log(error);
    }
    setLoading(false);
  };
	
	const validationSchema = yup.object({})
	
	const formik = useFormik({
		initialValues: {
			status: 'all',
			date: '',
			startDate: '',
			endDate: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true)
			setResetFilter(null)
			const valuesToSubmit = {status: values.status, date: values.date, startDate: values.startDate, endDate: values.endDate}
			setFilterValues(valuesToSubmit)
			await getLatestStats(valuesToSubmit);
			setSubmitting(false);
		}
	});
	
	function handleChange(e) {
		setPage(e.target.textContent);
	}
	
	const today = new Date().toISOString().split("T")[0];
	const renderFilters = (
		<div className="transactions-filters">
			<form onSubmit={formik.handleSubmit}>
				<div className="os-flex gap-32">
					<TextField
						fullWidth
						id="status"
						name="status"
						select
						label="Statut du filleuil"
						value={formik.values.status}
						onChange={formik.handleChange}
						error={formik.touched.status && Boolean(formik.errors.status)}
						helperText={formik.touched.status && formik.errors.status}
						variant={"outlined"}
					>
						<MenuItem value={'all'}>Tous</MenuItem>
						<MenuItem value={'verified'}>Comptes validés</MenuItem>
						<MenuItem value={'unverified'}>Inscrits</MenuItem>
					</TextField>
					<TextField
						fullWidth
						id="date"
						name="date"
						select
						label="Période"
						value={formik.values.date}
						onChange={formik.handleChange}
						error={formik.touched.date && Boolean(formik.errors.date)}
						helperText={formik.touched.date && formik.errors.date}
						variant={"outlined"}
					>
						<MenuItem value={'last_30_days'}>30 derniers jours ({thirtyDaysAgo} - {new Date().toLocaleDateString()})</MenuItem>
						<MenuItem value={'current_year'}>Année en cours</MenuItem>
						<MenuItem value={'last_year'}>Année précédente</MenuItem>
						<MenuItem value={'custom'}>Personnalisée</MenuItem>
					</TextField>
				</div>
				{formik.values.date === 'custom' && (
					<>
						<p className="os-subtitle2">Période personnalisée</p>
						<div className="os-flex gap-32">
							<TextField
								fullWidth
								id="startDate"
								name="startDate"
								type="date"
								label="Date de début"
								value={formik.values.startDate}
								onChange={formik.handleChange}
								error={formik.touched.startDate && Boolean(formik.errors.startDate)}
								helperText={formik.touched.startDate && formik.errors.startDate}
								variant={"outlined"}
								InputProps={{inputProps: { min: "2022-01-01", max: today} }}
								InputLabelProps={{ shrink: true }}
							/>
							<TextField
								fullWidth
								id="endDate"
								name="endDate"
								type="date"
								label="Date de fin"
								value={formik.values.endDate}
								onChange={formik.handleChange}
								error={formik.touched.endDate && Boolean(formik.errors.endDate)}
								helperText={formik.touched.endDate && formik.errors.endDate}
								variant={"outlined"}
								InputProps={{inputProps: { min: "2022-01-01", max: today} }}
								InputLabelProps={{ shrink: true }}
							/>
						</div>
					</>
				)}
				<div className="user-survey-btn-container" style={{marginTop: 24}}>
					<p className="os-body2" style={{cursor: "pointer"}} onClick={() => setResetFilter(true)}>Réinitialiser les filtres</p>
					<button disabled={submitting} type="submit" className="user-survey-btn">Continuer</button>
				</div>
			</form>
		</div>
	)

  const tableLabels = [{ id: 'full_name', label: 'Filleul' }, { id: 'created_at', label: 'Inscrit le' }, {status: 'status', label: 'Statut'}, { id: 'account_verified_at', label: 'Premier dépot effectué le' }];

  return (
      <div className="os-flex__column my-referrals">
        <div>
	        {users.length > 5 && renderFilters}
	        <div className="os-flex gap-8">
		        <p>Résultats de la recherche: </p>
		        <div className="os-flex gap-8 doc-badges">
		          {(badges && badges.length > 0) && badges.map(b => getBadge(b.status, 'kyc_status', `${b.number} ${b.text}`))}
		        </div>
	        </div>
          {loading ? [1,2,3,4,5,6].map(e => <YourReferralsLoader key={e} />) :
              (users?.length > 0 ? <ReferralsTable tableData={users} tableLabels={tableLabels} title="Parrainages validés" />
               : <p className="os-subtitle1">Vous n'avez pas encore de parrainages validés</p>)
          }
	        {(users.length > 5 || page > 1) && (
		        <div className="os-flex pagination-container gap-16 transactions-filters">
			        {(!loading && meta.total_pages > 0) ? <Pagination count={!loading ? meta.total_pages : 10} shape="rounded" page={parseInt(page)} onChange={handleChange}/> : <div/>}
			        <PerPage perPage={perPage} setPerPage={setPerPage} />
		        </div>
	        )}
        </div>
      </div>
  );
};

YourReferrals.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default YourReferrals;
