// @ts-ignore
import Network from './Network.ts';

class CabinetNetwork extends Network {
  async getCabinet(cabinetId: number) {
    try {
      return await this.get(`cgp_companies/${cabinetId}`);
    } catch (err) {
      return false;
    }
  }

  async addInvitations(invitationData: { cgp_company_id: number; invitations: Array<{ inviteeFirstName: string; inviteeLastName: string; inviteeEmail: string; role: string }> }) {
    try {
      const { cgp_company_id, invitations } = invitationData;

      // Convert the keys from camelCase to snake_case
      const formattedInvitations = invitations.map(invitation => ({
        invitee_first_name: invitation.inviteeFirstName,
        invitee_last_name: invitation.inviteeLastName,
        invitee_email: invitation.inviteeEmail,
        role: invitation.role
      }));

      // Send the request to the back-end with the correctly formatted data
      return await this.post(`cgp_invitations`, { invitations: formattedInvitations, cgp_company_id: cgp_company_id });
    } catch (err) {
      return { error: "Une erreur est survenue lors de la création des invitations." };
    }
  }

  async updateCompany(id: number, params: Object) {
    return await this.put(`/cgp_companies/${id}`, params);
  }

  async getMember(cgpUserId: number, cgpCompanyId: number) {
    try {
      return await this.get(`cgp_companies/${cgpCompanyId}/view_cgp_user/${cgpUserId}`);
    } catch (err) {
      return false;
    }
  }
}

export default new CabinetNetwork();
