import React, {useState} from "react";

import OsCard from "../../../../components/card/basic";
import OSButton from "../../../../components/Button";
import EditIcon from "@mui/icons-material/Edit";
import OsModal from "../../../../components/Modals";
import CgpCompanyEditForm from "../../../../Forms/Cgp/EditForm";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import WalletIcon from "@mui/icons-material/Wallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import OsTooltip from "../../../../components/Tooltips";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoneyHelper from "../../../../Helpers/MoneyHelper";
import AddIcon from "@mui/icons-material/Add";
import InsightsIcon from '@mui/icons-material/Insights';
import TableLoader from "../../../../components/Loaders/Table/TableLoader";

const Details = ({ cabinet, loading, refreshTrigger}) => {
	const [infosModal, setInfosModal] = useState(false);
	
	const setSuccessActions = (modal, refresh) => {
		setInfosModal(!infosModal)
		refresh && refreshTrigger(true)
	}
	
	const cardLinks = [
		{id: 1, title: "Clients créés", data: cabinet?.clients_count, content: "Clients créés sur Openstone", buttonText: "Créer mon premier client", buttonLink: "/cgp/clients", icon: <PeopleOutlineIcon />},
		{id: 2, title: "Clients investisseurs", data: cabinet?.active_clients_count, content: "Clients ayant souscrit sur Openstone", buttonText: "Gérer ma clientèle", buttonLink: "/cgp/clients", icon: <WalletIcon />},
		{id: 3, title: "Volume sous gestion", data: parseFloat(cabinet?.aum), content: "Volume total sous gestion sur Openstone", buttonText: "Réaliser une souscription", buttonLink: "/cgp/clients", icon: <AccountBalanceIcon />},
	]
	
	return (
		<OsCard shadows="no-shadow">
			<div className="os-flex os-card--children-menu">
				<div>
					<h4>Informations sur le cabinet</h4>
					<p>Consulter et gérer les informations de votre cabinet</p>
				</div>
				<OSButton variant="primary"
									onClick={() => setInfosModal(true)}
									title="Éditer les informations"
									fit
									size="medium"
									icon={<EditIcon/>}/>
			</div>
			<div className="user-infos-names">
				{loading ? (
					<TableLoader />
				) : (
					<div className="user-infos-names" style={{marginTop: 24}}>
						<p><span className="field-title">Nom :</span> {cabinet?.legal_name}</p>
						<p><span className="field-title">Siren :</span> {cabinet?.siren}</p>
						<p><span className="field-title">Identifiant ORIAS :</span> {cabinet?.orias_id}</p>
					</div>
				)}
			</div>
			
			<div className="os-flex os-card--children-menu" style={{marginTop: 32, border: "none"}}>
				<div>
					<h4>Activité de votre cabinet</h4>
					<p>Consulter et gérer les informations de votre cabinet</p>
				</div>
				<OSButton variant="secondary"
									link="/cgp/statistiques"
									title="Consulter les statistiques"
									fit
									size="small"
									icon={<InsightsIcon />}
				/>
			</div>
			<div className="os-flex dashboard-cards">
				{cardLinks.map((cl, index) =>
					<div className="os-card os-card--bg-grey os-flex__column stats-card">
						<div className="os-flex gap-8 card-icon-container">
							<div className="os-flex round-icon">
								{cl.icon}
							</div>
							<div className="os-flex card-icon-title">
								<p>{cl.title}</p>
								<OsTooltip title={<Typography color="inherit">{cl.content}</Typography>}>
									<div className="tooltip-btnOpportunityCardInfos"><InfoOutlinedIcon /></div>
								</OsTooltip>
							</div>
						</div>
						{cl.data > 0 ? <h4 className="os-h4 data-card">{cl.id === 3 ? MoneyHelper.formatEuro(cl.data, true, 0, 0) : cl.data}</h4> : <OSButton variant="primary-full" size="small" title={cl.buttonText} fit link={cl.buttonLink} icon={<AddIcon />} />}
					</div>
				)}
			</div>
			
			<OsModal title="Éditer les informations" isShowing={infosModal} hide={() => setInfosModal(false)}>
				<CgpCompanyEditForm cabinet={cabinet} onSuccess={setSuccessActions} />
			</OsModal>
		</OsCard>
	)
};

export default Details;
