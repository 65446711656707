import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TextField from "@material-ui/core/TextField";
import OsSelect from "../../components/Form/OsSelect";
import OSButton from "../../components/Button";
import CabinetNetwork from "../../config/Network/CabinetNetwork.ts";
import {toast} from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';

const CgpInvitationsForm = ({ cabinetId, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [invitations, setInvitations] = useState([{ inviteeName: '', inviteeEmail: '', role: '' }]);
	
	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		invitations: Yup.array().of(
			Yup.object().shape({
				inviteeFirstName: Yup.string().required("Veuillez saisir le prénom du collaborateur"),
				inviteeLastName: Yup.string().required("Veuillez saisir le nom du collaborateur"),
				inviteeEmail: Yup.string().email("Veuillez saisir un email valide").required("Veuillez saisir l'email du collaborateur"),
				role: Yup.string().required("Veuillez sélectionner un rôle"),
			})
		),
	});
	
	// Function to add invitations
	async function addInvitations(invitationData) {
		try {
			const response = await CabinetNetwork.addInvitations(invitationData);
			if (response.errors) {
				toast.error(response.errors.map(err => err.errors).join(', '), {
					toastId: "invitationErrorToast"
				})
			} else {
				toast.success("Les invitations sont envoyées", {
					toastId: "invitationSuccessToast"
				})
				onSuccess(false, true);
			}
		} catch (error) {
			toast.error("Une erreur est survenue lors de l'ajout des invitations.", {
				toastId: "invitationErrorToast"
			})
		} finally {
			setSubmitting(false);
			setIsLoading(false);
		}
	}
	
	// Set up formik for form handling
	const formik = useFormik({
		initialValues: {
			invitations: invitations,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			setIsLoading(true);
			
			// Send all invitations at once in a single request
			await addInvitations({
				cgp_company_id: cabinetId,
				invitations: values.invitations,
			});
		},
	});
	
	const addInvitationLine = () => {
		setInvitations([...formik.values.invitations, { inviteeName: '', inviteeEmail: '', role: '' }]);
		formik.setFieldValue('invitations', [...formik.values.invitations, { inviteeName: '', inviteeEmail: '', role: '' }]);
	};
	
	const deleteInvitationLine = (index) => {
		const updatedInvitations = formik.values.invitations.filter((_, i) => i !== index);
		setInvitations(updatedInvitations);
		formik.setFieldValue('invitations', updatedInvitations);
	};
	
	return (
		<div className="cgp-modal-form">
			<form className="os-flex__column gap-16 os-user-survey-form" onSubmit={formik.handleSubmit}>
				{formik.values.invitations.map((invitation, index) => (
					<div key={index} className="os-flex gap-16 invitation-item">
						<TextField
							fullWidth
							id={`invitations.${index}.inviteeFirstName`}
							name={`invitations.${index}.inviteeFirstName`}
							label="Prénom"
							value={formik.values.invitations[index].inviteeFirstName}
							onChange={formik.handleChange}
							error={formik.touched.invitations?.[index]?.inviteeFirstName && Boolean(formik.errors.invitations?.[index]?.inviteeFirstName)}
							helperText={formik.touched.invitations?.[index]?.inviteeFirstName && formik.errors.invitations?.[index]?.inviteeFirstName}
							variant="outlined"
							className="by-1"
						/>
						
						<TextField
							fullWidth
							id={`invitations.${index}.inviteeLastName`}
							name={`invitations.${index}.inviteeLastName`}
							label="Nom"
							value={formik.values.invitations[index].inviteeLastName}
							onChange={formik.handleChange}
							error={formik.touched.invitations?.[index]?.inviteeLastName && Boolean(formik.errors.invitations?.[index]?.inviteeLastName)}
							helperText={formik.touched.invitations?.[index]?.inviteeLastName && formik.errors.invitations?.[index]?.inviteeLastName}
							variant="outlined"
							className="by-1"
						/>
						
						<TextField
							fullWidth
							id={`invitations.${index}.inviteeEmail`}
							name={`invitations.${index}.inviteeEmail`}
							label="E-mail"
							value={formik.values.invitations[index].inviteeEmail}
							onChange={formik.handleChange}
							error={formik.touched.invitations?.[index]?.inviteeEmail && Boolean(formik.errors.invitations?.[index]?.inviteeEmail)}
							helperText={formik.touched.invitations?.[index]?.inviteeEmail && formik.errors.invitations?.[index]?.inviteeEmail}
							variant="outlined"
							className="by-1"
						/>
						
						<OsSelect
							formik={formik}
							name={`invitations.${index}.role`}
							label="Rôle"
							options={[
								{ value: 'owner', label: 'Administrateur' },
								{ value: 'cgp', label: 'Conseiller' },
							]}
						/>
						
						{index > 0 && (
							<DeleteIcon
								onClick={() => deleteInvitationLine(index)}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</div>
				))}
				
				<div className="user-survey-btn-container">
					<OSButton title="Ajouter une autre ligne" variant="no_border" size="medium" onClick={addInvitationLine} disabled={isLoading} fit />
					<button type="submit" className="user-survey-btn user-survey-btn-warning" disabled={submitting || isLoading}>
						Continuer
					</button>
				</div>
			</form>
		</div>
	);
};

export default CgpInvitationsForm;
