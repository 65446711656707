import React from 'react';
import ContentLoader from 'react-content-loader';

import './style.scss';

const FullPageLoader = () => (
	<div className="full-page-loader-container">
		<ContentLoader
			speed={2}
			width="100%"
			height="100%"
			viewBox="0 0 800 400"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		>
			{/* Simulate a loading header */}
			<rect x="20" y="20" rx="4" ry="4" width="760" height="24" />
			
			{/* Simulate card sections */}
			<rect x="20" y="80" rx="4" ry="4" width="300" height="180" />
			<rect x="360" y="80" rx="4" ry="4" width="420" height="180" />
			
			{/* Simulate a button */}
			<rect x="20" y="300" rx="20" ry="20" width="200" height="40" />
			
			{/* Simulate some more details */}
			<rect x="20" y="360" rx="4" ry="4" width="760" height="24" />
		</ContentLoader>
	</div>
);

export default FullPageLoader;
