import React, { useState } from "react";

import OsCard from "../../../../components/card/basic";
import OSButton from "../../../../components/Button";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MembersTable from "../../../../components/Table/Cgp/MembersTable";
import OsModal from "../../../../components/Modals";
import CgpInvitationsForm from "../../../../Forms/Cgp/invitationsForm";
import InvitationsTable from "../../../../components/Table/Cgp/InvitationsTable";
import TableLoader from "../../../../components/Loaders/Table/TableLoader";
import EmptyContent from "../../../../components/empty-content/EmptyContent";

const Members = ({ cabinet, loading, refreshTrigger }) => {
	const [inviteModal, setInviteModal] = useState(false);
	
	const setSuccessActions = (modal, refresh) => {
		setInviteModal(modal);
		refresh && refreshTrigger(true);
	};
	
	return (
		<OsCard shadows="no-shadow">
			<div className="os-flex os-card--children-menu">
				<div>
					<h4 className="os-h4">Collaborateurs</h4>
					<p>Gérez les membres de votre cabinet et leurs rôles</p>
				</div>
				<OSButton
					variant="primary"
					onClick={() => setInviteModal(true)}
					title="Inviter un collaborateur"
					fit
					size="medium"
					icon={<GroupAddIcon />}
				/>
			</div>
			<div className="members-table">
				{loading ? (
					<TableLoader />
				) : cabinet?.cgp_company_users.length > 0 ? (
					<MembersTable
						tableLabels={[
							{ id: "name", label: "Nom" },
							{ id: "created_at", label: "Date d'ajout" },
							{ id: "amount", label: "Nombre de clients" },
							{ id: "amount", label: "Montant sous gestion" },
							{ id: "role", label: "Rôle" },
							{ id: "status", label: "Statut" },
							{ id: "actions", label: "Actions" },
						]}
						tableData={cabinet?.cgp_company_users}
						title="Membres"
					/>
				) : (
					<p>Aucun membre disponible</p>
				)}
			</div>
			
			<div className="os-flex os-card--children-menu">
				<div>
					<p className="os-subtitle1">Invitations</p>
					<p>Consulter et gérer les invitations de vos membres</p>
				</div>
			</div>
			<div className="members-table">
				{loading ? (
				  <TableLoader />
				) : cabinet?.cgp_invitations.length > 0 ? (
					<InvitationsTable
						tableLabels={[
							{ id: "name", label: "Nom" },
							{ id: "created_at", label: "Date d'ajout" },
							{ id: "status", label: "Statut" },
						]}
						tableData={cabinet?.cgp_invitations}
						title="Invitations"
					/>
				) : (
					<EmptyContent text="Vous n'avez pas d'invitations en cours" />
				)}
			</div>
			<OsModal
				title="Inviter des collaborateurs"
				isShowing={inviteModal}
				hide={() => setInviteModal(false)}>
				<CgpInvitationsForm
					cabinetId={cabinet?.id}
					onSuccess={setSuccessActions}
				/>
			</OsModal>
		</OsCard>
	);
};

export default Members;
