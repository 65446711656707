import React from "react";
import ContentLoader from "react-content-loader";

const OpportunityViewLoader = (props) => (
	<ContentLoader
		speed={2}
		width="100%"
		height="100%"
		viewBox="0 0 400 600"
		backgroundColor="#F8FBFC"
		foregroundColor="#DFE3E8"
		{...props}
	>
		{/* Title and navigation */}
		<rect x="16" y="15" rx="4" ry="4" width="60%" height="20" /> {/* Main title */}
		<rect x="16" y="50" rx="4" ry="4" width="30%" height="10" /> {/* Breadcrumb */}
		
		{/* Main image placeholder */}
		<rect x="16" y="80" rx="4" ry="4" width="92%" height="120" /> {/* Banner image */}
		
		{/* Investment details section on the right */}
		<rect x="250" y="210" rx="4" ry="4" width="25%" height="15" /> {/* TRI net cible */}
		<rect x="250" y="235" rx="4" ry="4" width="20%" height="10" /> {/* Duration */}
		<rect x="250" y="255" rx="4" ry="4" width="20%" height="10" /> {/* Minimum Investment */}
		<rect x="250" y="275" rx="4" ry="4" width="30%" height="15" /> {/* Client selector */}
		<rect x="250" y="300" rx="4" ry="4" width="30%" height="30" /> {/* Subscription button */}
		
		{/* Question and contact section */}
		<rect x="16" y="220" rx="4" ry="4" width="50%" height="20" /> {/* "Une Question?" */}
		<rect x="16" y="245" rx="4" ry="4" width="15%" height="15" /> {/* Contact options */}
		
		{/* Tabs for sections like "L'investissement" */}
		<rect x="16" y="290" rx="4" ry="4" width="15%" height="15" />
		<rect x="80" y="290" rx="4" ry="4" width="15%" height="15" />
		<rect x="144" y="290" rx="4" ry="4" width="15%" height="15" />
		<rect x="208" y="290" rx="4" ry="4" width="15%" height="15" />
		
		{/* Description section */}
		<rect x="16" y="320" rx="4" ry="4" width="90%" height="10" />
		<rect x="16" y="335" rx="4" ry="4" width="85%" height="10" />
		<rect x="16" y="350" rx="4" ry="4" width="75%" height="10" />
		<rect x="16" y="365" rx="4" ry="4" width="80%" height="10" />
		<rect x="16" y="380" rx="4" ry="4" width="90%" height="10" />
		
		{/* Additional content sections */}
		<rect x="16" y="400" rx="4" ry="4" width="92%" height="200" /> {/* Additional info area */}
	</ContentLoader>
);

export default OpportunityViewLoader;
