import Network from './Network.ts';

class ProductNetwork extends Network {
  async getProducts(category: string) {
    return await this.get('/products', { category_name: null });
  }

  async getProduct(productId: number, recordId: string, recordType: string) {
    return await this.get(`/products/${productId}`, { record_id: recordId, record_type: recordType });
  }

  async createInterest(productId: string) {
    return await this.post(`/interests/create`, {
      interestable_type: 'product',
      interestable_id: productId
    });
  }

  async getPublicProduct(productId) {
    return await this.get(`/products/${productId}/public`);
  }
}

export default new ProductNetwork();
