import PropTypes from 'prop-types';
// @mui
import {
	Card,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableContainer,
} from '@mui/material';
// components
import Scrollbar from '../../scrollbar';
import {TableHeadCustom} from '../index.js';
import moment from "moment/moment";
import SouthWestIcon from '@mui/icons-material/SouthWest';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PercentIcon from '@mui/icons-material/Percent';

import MoneyHelper from "../../../Helpers/MoneyHelper";
import React from "react";

import '../myTable.scss';
import Badge from "../../Badge/Badge";
import PersonIcon from "@mui/icons-material/Person";
import OSButton from "../../Button";
import {useNavigate} from "react-router-dom";

// ----------------------------------------------------------------------

AllInvestmentsTable.propTypes = {
	title: PropTypes.string,
	tableData: PropTypes.array,
	subheader: PropTypes.string,
	tableLabels: PropTypes.array,
};

export default function AllInvestmentsTable({ title, subheader, tableData, tableLabels, ...other }) {
	return (
		<Card {...other}>
			<TableContainer sx={{ overflow: 'unset' }}>
				<Scrollbar>
					<Table sx={{ minWidth: 720 }}>
						<TableHeadCustom headLabel={tableLabels} />
						<TableBody>
							{tableData?.map((row, index) => row.transaction_type !== 'fees' && (
								<AllInvestmentsTableRow key={`tableCell${index}-${row.created_at}-${row.notes}`} row={row} index={index} {...other}/>
							))}
						</TableBody>
					</Table>
				</Scrollbar>
			</TableContainer>
		</Card>
	);
}

// ----------------------------------------------------------------------

AllInvestmentsTableRow.propTypes = {
	row: PropTypes.shape({
		title: PropTypes.string,
		amount: PropTypes.string,
		status: PropTypes.string,
		date: PropTypes.string,
	}),
};

const checkRowType = (transaction) => {
	let title;
	let growth;
	let amount = MoneyHelper.formatEuro(transaction.amount);
	if (transaction.transaction_type === 'withdraw') {
		title = 'Retrait';
		growth = <div className="arrow-container arrow-container-red"><SouthWestIcon /></div>;
	} else if (transaction.transaction_type === 'top_up') {
		title = 'Soucription';
		growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
	} else if (transaction.transaction_type === 'interest_from_base_rate') {
		title = 'Intérêts';
		growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
		amount = MoneyHelper.formatEuro(transaction.amount, false, 4, 4);
	} else if (transaction.transaction_type === 'taxes') {
		title = 'Prélèvements et impôts';
		growth = <div className="arrow-container arrow-container-red"><SouthWestIcon /></div>
		amount = MoneyHelper.formatEuro(transaction.amount, false, 2, 2);
	} else if (transaction.transaction_type === 'cgp_entry_fees') {
		title = "Frais d'entrée";
		growth = <div className="arrow-container arrow-container-info"><PercentIcon /></div>
	}
	return {title, growth, amount};
};

const checkRowStatus = (transaction) => {
  let status;
  let color;
  if (transaction.status === 'success' || transaction.status === 'paid') {
	  status = 'Validé';
		color = 'success';
  }
  return {status, color};
};

function AllInvestmentsTableRow({ row, index, ...other }) {
	const navigate = useNavigate();
	
	function goToInvestments(row) {
		row.name === 'Livret P' ? navigate(`/cgp/clients/${row.related_object.id}/investissement/${row.product.category_slug}/${row.product.category_slug}`, {state: {id: row.product.id, clientId: row.related_object.id.toString(), clientType: row.related_object.type, name: row.product.name}}) :
			navigate(`/cgp/clients/${row.related_object.id}/investissement/${row.product.category_slug}/${row.product.slug}`, {state: {id: row.product.id, clientId: row.related_object.id.toString(), clientType: row.related_object.type, name: row.product.name}})
	}
	
  return (
	<>
	  {row.transaction_type !== 'cgp_entry_fees' && (
		<TableRow className={`table-row ${index % 2 === 0 ? 'even' : 'odd'}`}>
		  <TableCell>
			<div style={{ display: "flex", alignItems: 'center', gap: 10 }}>
				{checkRowType(row, other).growth}
				<span className="ds_category_name">{checkRowType(row, other).title}</span>
			</div>
		  </TableCell>
			
			<TableCell>
				{row?.cgp_user?.full_name || 'N/A'}
			</TableCell>
			
			<TableCell>
				{row?.product.abreviation_name|| 'N/A'}
			</TableCell>
		  
		  <TableCell>
			<span className="ds_category_name">{moment(row.created_at, 'YYYY-MM-DD').format('DD/MM/yyyy')}</span>
		  </TableCell>
			
		  <TableCell>
				<span className="ds_category_name">{checkRowType(row, other).amount}</span>
		  </TableCell>
			
		  <TableCell>
				<Badge color={checkRowStatus(row).color}>{checkRowStatus(row).status}</Badge>
		  </TableCell>
			
			<TableCell>
				<OSButton onClick={() => goToInvestments(row)} variant="no_border-info" fit size="small" title="Voir le détail" />
		  </TableCell>
		</TableRow>
	  )}
	</>
  );
}
