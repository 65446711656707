import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import Images from "../../../assets/images";
import PublicNav from "../../../components/Navbar/publicNav";
import CgpSignUpForm from "../../../Forms/Auth/SignUp/cgp";
import Phone from "../../../components/Phone";
import Footer from "../../../components/Footer";
import Badge from "../../../components/Badge/Badge";
import CheckIcon from '@mui/icons-material/Check';

import images from '../../../assets/images/index';

import './style.scss';

const CgpSignUp = () => {
	const navigate = useNavigate();
	const authToken = localStorage.getItem('CgpUserAuthToken');
	const [otp, setOtp] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [inviteData, setInviteData] = useState({ email: '', name: '', token: '', firstName: '', lastName: '', companyName: '', oriasId: '' });
	const [searchParams] = useSearchParams();
	
	const elements = [
		{img: Images.cgp.accesibility, content: <p className="os-body1 auth-img-text ">Un accès à des produits haut de gamme à partir de <span className="important">10 000€</span> seulement</p>},
		{img: Images.cgp.growth, content: <p className="os-body1 auth-img-text "><span className="important">Une sélection très rigoureuse</span>.<br/>Notre équipe approuve moins de 10 % des deals qu'elle évalue avec des performances à partir de <span className="important">10%</span></p>},
		{img: Images.cgp.speed, content: <p className="os-body1 auth-img-text ">Une souscription <span className="important">100% en ligne</span></p>},
		{img: Images.cgp.independant, content: <p className="os-body1 auth-img-text ">Un <span className="important">contrôle total de votre clientèle</span>. <br/>Openstone ne sollicite en aucun cas vos clients</p>},
	];
	
	async function fetchUser() {
		await authenticationStore.loadCgpUserData().then(response => {
			setUser(response);
			setLoading(false);
		});
	}
	
	useEffect(() => {
		otp && fetchUser();
	}, [otp]);
	
	useEffect(() => {
		if (!otp && authToken && authToken !== 'undefined') {
			navigate('/sign-up/cgp/documents');
		}
	}, [authToken, navigate]);
	
	useEffect(() => {
		const token = searchParams.get('token');
		const email = searchParams.get('email');
		const firstName = searchParams.get('first_name');
		const lastName = searchParams.get('last_name');
		const companyId = searchParams.get('company_id');
		const companyName = searchParams.get('company_name');
		const companySiren = searchParams.get('company_siren');
		const companyOrias = searchParams.get('company_orias');
		
		if (token && firstName && lastName) {
			setInviteData({ token, email, firstName, lastName, companyId, companyName, companySiren, companyOrias });
		}
	}, [searchParams]);
	
	return (
		<>
			<Helmet>
				<title>Inscription partenaires</title>
			</Helmet>
			<PublicNav />
			<div className="os-flex collab-container">
				<div className="collab-left-container">
					<div className="sub-container">
						<img src={Images.brand.longLogo} className="auth-os-logo" />
						<h4 className="os-h4" style={{ color: "white" }}>Utilisez Openstone pour la gestion de vos clients</h4>
						<div className="avantages-container">
							{elements.map((e, index) => (
								<div className="os-flex avantage-element" key={index}>
									<CheckIcon style={{ color: 'white' }} />
									<span className="os-body2">
										{e.content}
									</span>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="collab-auth-container">
					<div className="collab-auth-card">
						{inviteData?.token && (
							<div className="info-callout info-callout__blue">
								<p>Vous avez été invité à rejoindre <strong>{inviteData.companyName}</strong> sur Openstone.<br/> Pour continuer veuillez remplir vos informations</p>
							</div>
						)}
						{otp ? (
							<>
								<h4 className="os-h4">Vérifier votre numéro de téléphone</h4>
								<span className="os-caption signup-text">Pour la sécurité de votre compte, veuillez vérifier votre numéro de téléphone</span>
								<Phone user={user} page="signUp" recordType="cgp" />
							</>
						) : (
							<>
								<div className="os-flex__column top-container" style={{ justifyContent: "space-between" }}>
									<div className="os-flex top-form sign-up-top-container">
										<div className="os-flex badges-page-container">
											<h4 className="os-h4">Inscription</h4>
											<div style={{ marginTop: 8 }}>
												<Badge color="dark-gradient">Espace partenaire</Badge>
											</div>
										</div>
										<span className="os-caption signup-text">Déjà inscrit ?
											<Link to="/sign-in/cgp" className="signup-link signup-link-grey"> Connectez-vous !</Link>
										</span>
									</div>
								</div>
								<CgpSignUpForm setPhone={setOtp} inviteData={inviteData} />
							</>
						)}
					</div>
					<div className="labels-signup">
						<img src={images.brand.labels} alt="Labels Openstone" />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CgpSignUp;
