import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import './style.scss';

const WorkspaceSwitcher = ({ onSwitch }) => {
	const [activeWorkspace, setActiveWorkspace] = useState(sessionStorage.getItem('activeWorkspace') || 'cgp');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	
	const handleSwitch = (workspace) => {
		setActiveWorkspace(workspace);
		onSwitch(workspace);
		setIsDropdownOpen(false);
	};
	
	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	
	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === 'activeWorkspace' && event.newValue) {
				setActiveWorkspace(event.newValue);
			}
		};
		window.addEventListener('storage', handleStorageChange);
		
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);
	
	return (
		<div className="workspace-switcher">
			<div className="os-flex dropdown-toggle" onClick={toggleDropdown}>
				<span>{activeWorkspace === 'cgp' ? 'Espace conseiller' : 'Espace cabinet'}</span>
				<UnfoldMoreIcon />
			</div>
			{isDropdownOpen && (
				<div className="wk-dropdown-menu">
					<div
						className={`workspace-item ${activeWorkspace === 'cgp' ? 'active' : ''}`}
						onClick={() => handleSwitch('cgp')}>
						<span>Espace conseiller</span>
					</div>
					<div
						className={`workspace-item ${activeWorkspace === 'companyWorkspace' ? 'active' : ''}`}
						onClick={() => handleSwitch('companyWorkspace')}>
						<span>Espace cabinet</span>
					</div>
				</div>
			)}
		</div>
	);
};

WorkspaceSwitcher.propTypes = {
	onSwitch: PropTypes.func.isRequired,
};

export default WorkspaceSwitcher;
