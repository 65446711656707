import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import CabinetNetwork from "../../../config/Network/CabinetNetwork.ts";

import Details from "./Sections/details";
import Members from "./Sections/members";
import Documents from "./Sections/document";

import './style.scss';

const Cabinet = ({ cgpUser }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [tab, setTab] = useState("Overview");
	const [cabinet, setCabinet] = useState(null);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	
	const fetchCabinet = async () => {
		setLoading(true);
		const response = await CabinetNetwork.getCabinet(cgpUser?.cgp_company?.id);
		if (response) {
			setLoading(false);
			setRefresh(false);
			setCabinet(response);
		}
	};
	
	const handleChange = (_event, newValue) => {
		setTab(newValue);
		navigate(`/cgp/cabinets/${newValue.toLowerCase()}`);
	};
	
	const checkCurrentTab = () => {
		switch (tab) {
			case "Overview":
				return <Details cabinet={cabinet} loading={loading} refreshTrigger={() => setRefresh()} />;
			case "Collaborateurs":
				return <Members cabinet={cabinet} loading={loading} refreshTrigger={() => setRefresh()} />;
			case "Documents":
				return <Documents documents={cabinet?.documents} loading={loading} />;
			default:
				return <Details cabinet={cabinet} loading={loading} />;
		}
	};
	
	useEffect(() => {
		cgpUser && fetchCabinet();
	}, [cgpUser, refresh]);
	
	useEffect(() => {
		const path = location.pathname.split('/').pop();
		if (path === 'overview') {
			setTab('Overview');
		} else if (path === 'collaborateurs') {
			setTab('Collaborateurs');
		} else if (path === 'invitations') {
			setTab('Invitations');
		} else if (path === 'documents') {
			setTab('Documents');
		} else {
			setTab('Overview');
		}
	}, [location.pathname]);
	
	return (
		<div className="cabinets-container">
			<div className="cabinets-top-container">
				<h3 className="os-h3">Espace cabinet - {cgpUser?.cgp_company?.legal_name}</h3>
				<div className="cabinet-tabs-container">
					<Tabs
						value={tab}
						onChange={handleChange}
						variant="fullWidth"
						className="custom-tabs"
					>
						<Tab
							label="Overview"
							value="Overview"
							className={tab === "Overview" ? "active-tab" : ""}
						/>
						<Tab
							label="Collaborateurs"
							value="Collaborateurs"
							className={tab === "Collaborateurs" ? "active-tab" : ""}
						/>
						{/*<Tab
							label="Documents"
							value="Documents"
							className={tab === "Documents" ? "active-tab" : ""}
						/>*/}
					</Tabs>
				</div>
			</div>
			<div className="cabinet-element-container">
				{checkCurrentTab()}
			</div>
		</div>
	);
};

export default Cabinet;
