import React from "react";
import moment from "moment";
import {
	Card,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableContainer
} from '@mui/material';
import Scrollbar from '../../scrollbar';
import { TableHeadCustom } from '../index.js';
import getBadge from "../../Badge/getBadge";

import '../myTable.scss';

// ----------------------------------------------------------------------
export default function InvitationsTable({ title, subheader, tableData, tableLabels, ...other }) {
	return (
		<Card {...other}>
			<TableContainer sx={{ overflow: 'unset' }}>
				<Scrollbar>
					<Table sx={{ minWidth: 720 }}>
						<TableHeadCustom headLabel={tableLabels} />
						
						<TableBody>
							{tableData.map(row => (
								<InvitationsTableRow key={`${row.id}-${row.invitee_name}`} row={row} />
							))}
						</TableBody>
					</Table>
				</Scrollbar>
			</TableContainer>
		</Card>
	);
}

// ----------------------------------------------------------------------

function InvitationsTableRow({ row }) {
	return (
		<>
			<TableRow>
				<TableCell>
					<div className="os-flex__column">
						<div>{row.invitee_name}</div>
						<div>{row.invitee_email}</div>
					</div>
				</TableCell>
				<TableCell>
					<div>{moment(row.created_at).locale('fr').format('DD/MM/YYYY')}</div>
				</TableCell>
				<TableCell>
					{getBadge(row.status, 'invitation')}
				</TableCell>
			</TableRow>
		</>
	);
}
