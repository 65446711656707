import React from 'react'
import ContentLoader from 'react-content-loader'

const TableLoader = props => {
	return (
		<ContentLoader
			width="100%"
			height={200}
			viewBox="0 0 1200 200"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...props}
		>
			<rect x="27" y="0" rx="4" ry="4" width="20" height="20" />
			<rect x="67" y="0" rx="10" ry="10" width="85" height="19" />
			<rect x="188" y="0" rx="10" ry="10" width="169" height="19" />
			<rect x="402" y="0" rx="10" ry="10" width="85" height="19" />
			<rect x="523" y="0" rx="10" ry="10" width="169" height="19" />
			<rect x="731" y="0" rx="10" ry="10" width="85" height="19" />
			<rect x="852" y="0" rx="10" ry="10" width="85" height="19" />
			
			<rect x="26" y="50" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="50" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="50" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="50" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="50" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="50" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="50" rx="10" ry="10" width="85" height="19" />
			
			<rect x="26" y="100" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="100" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="100" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="100" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="100" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="100" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="100" rx="10" ry="10" width="85" height="19" />
			
			<rect x="26" y="155" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="155" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="155" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="155" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="155" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="155" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="155" rx="10" ry="10" width="85" height="19" />
			
			<rect x="26" y="210" rx="4" ry="4" width="20" height="20" />
			<rect x="66" y="210" rx="10" ry="10" width="85" height="19" />
			<rect x="187" y="210" rx="10" ry="10" width="169" height="19" />
			<rect x="401" y="210" rx="10" ry="10" width="85" height="19" />
			<rect x="522" y="210" rx="10" ry="10" width="169" height="19" />
			<rect x="730" y="210" rx="10" ry="10" width="85" height="19" />
			<rect x="851" y="210" rx="10" ry="10" width="85" height="19" />
			
			<rect x="978" y="0" rx="10" ry="10" width="169" height="19" />
			<rect x="978" y="50" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="100" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="155" rx="10" ry="10" width="169" height="19" />
			<rect x="977" y="210" rx="10" ry="10" width="169" height="19" />
			
		</ContentLoader>
	)
}

export default TableLoader
