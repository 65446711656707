import React from 'react';
import OSButton from "../Button";
import {Avatar, AvatarGroup} from "@mui/material";

import Images from "../../assets/images";

import './style.scss';

/**
 *
 * @param toggle
 * @param {integer} number - To set the phone number inside the Contact block.
 * @param {string} text - Whether to display a content inside the Contact block.
 * @param {string} mail - Whether to display a mail inside the Contact block.
 * @param {String} name - Whether to display the Name inside the Contact block.
 * @param {string} image - Whether to add an image inside the Contact block.
 */

const ContactBlock = ({toggle, number, text, mail,name,image}) => {
  return (
    <div className="os-flex contact-container" style={{justifyContent: "space-between"}}>
      <div className="contact-left">
        <h4 className="os-h4">Nous sommes là pour vous</h4>
        <p className="os-body1 name">{text}</p>
        <OSButton link="https://meetings-eu1.hubspot.com/charles-antoine-lamoureux/openstone" variant="primary" fit size="large" title="Prendre rendez-vous" target />
      </div>
      <div className="contact-right">
        <h5 className="os-h5">Vos conseillers Openstone</h5>
        <div className="contact-user">
	        <AvatarGroup max={3}>
		        <Avatar alt="Remy Sharp" src={image} />
		        <Avatar alt="Travis Howard" src={Images.sebastien} />
		        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
		        <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
		        <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
	        </AvatarGroup>
	        <div className="contact-user__infos">
		        <a className="os-body1" href="mailto:partenaires@openstone.com">partenaires@openstone.com</a>
		        <br></br>
		        <span>{number}</span>
	        </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;
