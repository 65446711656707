import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast} from "react-toastify";

import TextField from "@material-ui/core/TextField";
import CabinetNetwork from "../../config/Network/CabinetNetwork.ts";

const CgpCompanyEditForm = ({ cabinet, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	
	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		legal_name: Yup.string().required("Veuillez saisir le nom de la société"),
		siren: Yup.string().required("Veuillez saisir le SIREN").matches(/^[0-9]{9}$/, "Le SIREN doit être composé de 9 chiffres"),
		orias_id: Yup.string().required('Requis').min(8, "Minimum 8 charactères").max(8, "Maximum 8 charactères"),
	});
	
	// Set up formik for form handling
	const formik = useFormik({
		initialValues: {
			legal_name: cabinet.legal_name || '',
			siren: cabinet.siren || '',
			orias_id: cabinet.orias_id || '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setIsLoading(true);
			setSubmitting(true);
			
			try {
				const response = await CabinetNetwork.updateCompany(cabinet.id, values);
				if (response.error) {
					toast.error(response.error, {
						toastId: "editCompanyErrorToast"
					})
				} else {
					toast.success("Les informations ont bien été enregistrées", {
						toastId: "editCompanyErrorToast"
					})
					onSuccess(true, true);
				}
			} catch (error) {
				toast.error("Une erreur est survenue lors de la mise à jour des informations.", {
					toastId: "editCompanyErrorToast"
				})
			} finally {
				setIsLoading(false);
				setSubmitting(false);
			}
		},
	});
	
	return (
		<div className="cgp-modal-form">
			<form className="os-flex__column gap-16" onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					id="legal_name"
					name="legal_name"
					label="Nom de la société"
					value={formik.values.legal_name}
					onChange={formik.handleChange}
					error={formik.touched.legal_name && Boolean(formik.errors.legal_name)}
					helperText={formik.touched.legal_name && formik.errors.legal_name}
					variant="outlined"
				/>
				
				<TextField
					fullWidth
					id="siren"
					name="siren"
					label="SIREN"
					value={formik.values.siren}
					onChange={formik.handleChange}
					error={formik.touched.siren && Boolean(formik.errors.siren)}
					helperText={formik.touched.siren && formik.errors.siren}
					variant="outlined"
				/>
				
				<TextField
					fullWidth
					id="orias_id"
					name="orias_id"
					label="Numéro ORIAS"
					value={formik.values.orias_id}
					onChange={formik.handleChange}
					error={formik.touched.orias_id && Boolean(formik.errors.orias_id)}
					helperText={formik.touched.orias_id && formik.errors.orias_id}
					variant="outlined"
				/>
				
				<div className="user-survey-btn-container">
					<button type="submit" className="user-survey-btn user-survey-btn-warning" disabled={submitting || isLoading}>
						Enregistrer
					</button>
				</div>
			</form>
		</div>
	);
};

export default CgpCompanyEditForm;
