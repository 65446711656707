// @ts-ignore
import Network from './Network.ts';

class StatisticsNetwork extends Network {
  async getInvestments({cgpUserId, cgpCompanyId}) {
    try {
      let url = `statistics/investments`;

      const queryParams = [];
      if (cgpUserId) {
        queryParams.push(`cgp_user_id=${cgpUserId}`);
      }
      if (cgpCompanyId) {
        queryParams.push(`cgp_company_id=${cgpCompanyId}`);
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
      }
      
      return await this.get(url);
    } catch (err) {
      return false;
    }
  }
}

export default new StatisticsNetwork();
